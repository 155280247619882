import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const SliderContainer = styled.div`
  width: 100vw; // Full viewport width
  max-width: 100%; // Ensure it does not exceed viewport width
  overflow: hidden;
  position: relative;
  margin: 0 auto; // Center the slider horizontally
`;

const SlideWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%; // Ensure the wrapper takes up full width
`;

const Slide = styled.div`
  flex: 0 0 100vw; // Ensure each slide takes up 100% of the viewport width
  width: 100vw; // Full viewport width
`;

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; // Ensure the image covers the slide area without distortion
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  cursor: pointer;
  z-index: 2;

  ${({ direction }) => direction === 'left' && `left: 10px;`}
  ${({ direction }) => direction === 'right' && `right: 10px;`}
`;

const Slider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
    const timer = setInterval(() => {
      handleNext();
    }, 3000);

    return () => clearInterval(timer);
  }, [currentIndex]);

  return (
    <SliderContainer>
      <Arrow direction="left" onClick={handlePrev}>
        &#10094;
      </Arrow>
      <SlideWrapper style={{ transform: `translateX(-${currentIndex * 100}vw)` }}>
        {images.map((image, index) => (
          <Slide key={index}>
            <SlideImage src={image} alt={`slide-${index}`} />
          </Slide>
        ))}
      </SlideWrapper>
      <Arrow direction="right" onClick={handleNext}>
        &#10095;
      </Arrow>
    </SliderContainer>
  );
};

export default Slider;
