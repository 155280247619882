import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import desktopVideo from '../assets/wide.mp4';
import mobileVideo from '../assets/vertical.mp4';

const VideoContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  object-fit: cover;
  pointer-events: none; /* Prevent video from being interacted with */
`;

const PromoVideo = () => {
  const videoRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener to detect screen size changes
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.src = isMobile ? mobileVideo : desktopVideo;
      video.load(); // Reload the video
      video.play(); // Ensure it auto-plays again
    }
  }, [isMobile]); // Reload the video each time screen size changes

  return (
    <VideoContainer>
      <Video ref={videoRef} autoPlay muted loop playsInline>
        {/* These source tags are not needed, handled by JavaScript */}
        {/* <source src={mobileVideo} type="video/mp4" media="(max-width: 767px)" />
        <source src={desktopVideo} type="video/mp4" media="(min-width: 768px)" /> */}
        Your browser does not support the video tag.
      </Video>
    </VideoContainer>
  );
};

export default PromoVideo;
