import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Slider from '../components/Slider';
import sliderDesktop from '../assets/Grillled.png';
import sliderMobile from '../assets/grilledmobile.png';

const PageContainer = styled.div`
  font-family: Arial, sans-serif;
  color: #fff;
`;

const Section = styled.section`
  background-color: #fff;
  padding: 50px 20px;
  text-align: center;
`;

const SectionHeadline = styled.h2`
  font-size: 32px;
  color: #D61F30; /* Main brand color */
  margin-bottom: 20px;
`;

const SectionText = styled.p`
  font-size: 18px;
  margin-bottom: 30px;
  color: #333;
`;

const CategoriesContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const CategoryButton = styled.button`
  background-color: ${({ active }) => (active ? '#D61F30' : 'white')}; /* Main brand color when active */
  color: ${({ active }) => (active ? 'white' : '#333')}; /* Text color */
  border: none;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;

  &:hover {
    background-color: ${({ active }) => (active ? '#D61F30' : '#D61F30')};
    color: ${({ active }) => (active ? 'white' : 'white')};
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 14px;
  }
`;

const ContactItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ContactItemCard = styled.div`
  background-color: #1995D3;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  max-width: 250px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 100%;
    width: 90%;
  }
`;

const ContactItemImage = styled.img`
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const ContactItemName = styled.h3`
  font-size: 18px;
  margin: 10px 0;
  color: #D61F30; /* Main brand color */
`;

const ContactItemPrice = styled.p`
  font-size: 16px;
  color: #333;
`;

function Contact() {
  const [activeCategory, setActiveCategory] = useState('All');
  const [sliderImage, setSliderImage] = useState(sliderDesktop);

  

 
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 768) {
        setSliderImage(sliderMobile);
      } else {
        setSliderImage(sliderDesktop);
      }
    };

    // Set the initial image
    handleResize();

    // Add event listener to resize
    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <PageContainer>
      {/* Pass the responsive slider image to the Slider component */}
      <Slider images={[sliderImage]} />
    
    </PageContainer>
  );
}

export default Contact;
