import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import backgroundImage from '../assets/backround.jpeg';


import Slider from '../components/Slider';
import sliderDesktop1 from '../assets/slider1.jpeg';
import sliderDesktop2 from '../assets/slider2.jpeg';
import sliderDesktop3 from '../assets/slider3.jpeg';
import sliderMobile from '../assets/grilledmobile.png';
const PageContainer = styled.div`
  font-family: Arial, sans-serif;
  color: #fff;
`;

const Section = styled.section`
  
  padding: 50px 20px;
  text-align: center;
`;

const SectionHeadline = styled.h2`
  font-size: 32px;
  color: #D61F30; /* Main brand color */
  margin-bottom: 20px;
`;

const CategoriesContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const CategoryButton = styled.button`
  background-color: ${({ active }) => (active ? '#D61F30' : 'white')}; /* Main brand color when active */
  color: ${({ active }) => (active ? 'white' : '#333')}; /* Text color */
  border: none;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;

  &:hover {
    background-color: ${({ active }) => (active ? '#D61F30' : '#D61F30')};
    color: ${({ active }) => (active ? 'white' : 'white')};
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 14px;
  }
`;

const MenuItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const MenuItemCard = styled.div`
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0; /* Optional: to adjust spacing */
  max-width: none;
`;

/* Keep the menu item image style the same */
const MenuItemImage = styled.img`
  width: 300px;
  height: auto;
  object-fit: cover;
  border-radius: 10px; /* Remove border radius if you don't want rounded corners */
  margin-bottom: 10px;
`;

/* Remove background color for the name card and set text color to black */
const NameCard = styled.div`
  background-color: transparent;
  padding: 0; /* Optional: Adjust padding if needed */
  margin-bottom: 0; /* Optional: Adjust spacing if needed */
`;

/* Change the text color to black */
const MenuItemName = styled.h3`
  font-size: 18px;
  color: black;
  margin: 0;
`;

/* Remove background color for the price card and set text color to black */
const PriceCard = styled.div`
  background-color: transparent;
  padding: 0; /* Optional: Adjust padding if needed */
`;

/* Change the text color to black */
const MenuItemPrice = styled.p`
  font-size: 16px;
  color: black;
  margin: 0;
`;

/* Menu Item Variants Styling */
const MenuItemVariants = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px; /* Adjust the spacing between variants */
  flex-wrap: nowrap; /* Ensure variants stay in one line */
`;

/* Adjust variant name styling */
const VariantName = styled.span`
  font-size: 14px;
  color: black;
`;

/* Adjust variant price styling */
const VariantPrice = styled.span`
  font-size: 14px;
  color: black;
`;


function Menu() {
  const [activeCategory, setActiveCategory] = useState('All');
  const [sliderImages, setSliderImages] = useState([]);

  const categories = ['All', 'Flame Grilled', 'Krispy Fried', 'Sides'];

  const menuItems = [
    // Existing menu items
   
    {
      id: 1,
      category: 'Flame Grilled',
      name: '1/4 Chicken',
      image: require('../assets/kk menu/quarterchicken.png'),
      variants: [
        { variant: '', price: 34.90 },
        { variant: 'with chips + ', price: 10 },

        
        
      ],
    },

    

    {
      id: 2,
      category: 'Flame Grilled',
      name: '1/2 Chicken',
      image: require('../assets/kk menu/halfchicken.png'),
      variants: [
        { variant: '', price: 64.90 },
        { variant: 'with chips + ', price: 15 },

        
        
      ],
    },

    {
      id: 3,
      category: 'Flame Grilled',
      name: 'Full Chicken',
      image: require('../assets/kk menu/11.png'),
      variants: [
        { variant: '', price: 124.90 },
        { variant: 'with chips + ', price: 20 },

        
        
      ],
    },

    {
      id: 4,
      category: 'Flame Grilled',
      name: 'Grilled Chicken Burger',
      image: require('../assets/kk menu/grilled burger.png'),
      variants: [
        { variant: '', price: 34.90 },
        { variant: 'with chips + ', price: 14 },

        
        
      ],
    },

    {
      id: 5,
      category: 'Flame Grilled',
      name: 'Double Grilled Chicken Burger',
      image: require('../assets/kk menu/double grilled burger.png'),
      variants: [
        { variant: '', price: 64.90 },
        { variant: 'with chips + ', price: 15 },

        
        
      ],
    },

    {
      id: 6,
      category: 'Flame Grilled',
      name: 'Grilled Beef Burger',
      image: require('../assets/kk menu/beef burger.png'),
      variants: [
        { variant: '', price: 35.90 },
        { variant: 'with chips + ', price: 14 },

        
        
      ],
    },

    {
      id: 7,
      category: 'Flame Grilled',
      name: 'Double Grilled Beef Burger',
      image: require('../assets/kk menu/double beef burger.png'),
      variants: [
        { variant: '', price: 59.90 },
        { variant: 'with chips + ', price: 15 },
       

        
        
      ],
    },

    {
      id: 8,
      category: 'Flame Grilled',
      name: 'Grilled Fafa Meal',
      image: require('../assets/kk menu/grilledfafa.png'),
      price: 154.90,
    },

    {
      id: 9,
      category: 'Flame Grilled',
      name: 'Grilled Family Fiesta',
      image: require('../assets/kk menu/17.png'),
      price: 239.90,
    },

    {
      id: 10,
      category: 'Krispy Fried',
      name: 'Krispy 1',
      image: require('../assets/kk menu/1.png'),
      variants: [
        { variant: '', price: 19.90 },
        { variant: 'with chips + ', price: 10 },

        
        
      ],
    },
    {
      id: 11,
      category: 'Krispy Fried',
      name: 'Krispy 2',
      image: require('../assets/kk menu/2.png'),
      variants: [
        { variant: '', price: 35.90 },
        { variant: 'with chips + ', price: 5 },

        
        
      ],
    },
    {
      id: 12,
      category: 'Krispy Fried',
      name: 'Krispy 3',
      image: require('../assets/kk menu/3.png'),
      variants: [
        { variant: '', price: 54.90 },
        { variant: 'with chips + ', price: 10 },

        
        
      ],
    },
    {
      id: 13,
      category: 'Krispy Fried',
      name: 'Krispy 4',
      image: require('../assets/kk menu/4.png'),
      variants: [
        { variant: '', price: 69.90 },
        { variant: 'with chips + ', price: 15 },

        
        
      ],
    },
    {
      id: 14,
      category: 'Krispy Fried',
      name: 'Krispy 8',
      image: require('../assets/kk menu/kripy 8.png'),
      variants: [
        { variant: '', price: 119.90 },
        { variant: 'with chips + ', price: 20 },

        
        
      ],
    },

    {
      id: 15,
      category: 'Krispy Fried',
      name: 'Krispy Burger',
      image: require('../assets/kk menu/krispyburger.png'),
      variants: [
        { variant: '', price: 35.90 },
        { variant: 'with chips + ', price: 15 },

        
        
      ],
    },

    {
      id: 16,
      category: 'Krispy Fried',
      name: 'Double Krispy Burger',
      image: require('../assets/kk menu/double grilled burger.png'),
      variants: [
        { variant: '', price: 64.90 },
        { variant: 'with chips + ', price: 15 },

        
        
      ],
    },

    

    {
      id: 17,
      category: 'Krispy Fried',
      name: 'Krispy Fafa Meal',
      image: require('../assets/kk menu/8.png'),
      price: 154.90,
    },

    {
      id: 18,
      category: 'Krispy Fried',
      name: 'Krispy Family Fiesta',
      image: require('../assets/kk menu/7.png'),
      price: 239.90,
    },



    

   
    {
      id: 19,
      category: 'Krispy Fried',
      name: 'Krispy Hot Wings',
      image: require('../assets/kk menu/20.png'),
      variants: [
        { variant: '4 wings', price: 39.90 },
        { variant: '6 wings', price: 49.90 },
        { variant: '12 wings', price: 99.90 },
        
      ],
    },

    {
      id: 20,
      category: 'Krispy Fried',
      name: 'Carolina Reaper',
      image: require('../assets/kk menu/18.png'),
      variants: [
        { variant: '4 wings', price: 42.90 },
        { variant: '6 wings', price: 54.90 },
        { variant: '12 wings', price: 104.90 },
        
      ],
    },

    {
      id: 21,
      category: 'Flame Grilled',
      name: 'Grilled Hot Wings',
      image: require('../assets/kk menu/19.png'),
      variants: [
        { variant: '4 wings', price: 39.90 },
        { variant: '6 wings', price: 49.90 },
        { variant: '12 wings', price: 99.90 },
        
      ],
    },

    {
      id: 22,
      category: 'Krispy Fried',
      name: 'Kripsy Chicken Twista',
      image: require('../assets/kk menu/krispy twista.png'),
      price: 39.90,
    },

    {
      id: 23,
      category: 'Krispy Fried',
      name: 'Slaw wrap',
      image: require('../assets/kk menu/slaw twista.png'),
      price: 34.90,
    },

    {
      id: 24,
      category: 'Krispy Fried',
      name: 'Fully Loaded Twista',
      image: require('../assets/kk menu/fully loaded twista.png'),
      price: 49.90,
    },
    {
      id: 25,
      category: 'Flame Grilled',
      name: 'Grilled Chicken Twista',
      image: require('../assets/kk menu/grilled twista.png'),
      price: 39.90,
    },

    {
      id: 26,
      category: 'Flame Grilled',
      name: '1/4 Chicken Paella',
      image: require('../assets/kk menu/21.png'),
      price: 39.90,
    },

    {
      id: 27,
      category: 'Flame Grilled',
      name: '1/2 Chicken Paella',
      image: require('../assets/kk menu/22.png'),
      price: 69.90,
    },

    {
      id: 28,
      category: 'Flame Grilled',
      name: 'Full Chicken Paella',
      image: require('../assets/kk menu/23.png'),
      price: 139.90,
    },

    {
      id: 29,
      category: 'Sides',
      name: 'Grilled Corn',
      image: require('../assets/kk menu/corn.png'),
      price: 19.90,
    },

    {
      id: 30,
      category: 'Sides',
      name: 'Strips and Rice',
      image: require('../assets/kk menu/strips and rice.png'),
      price: 35.90,
    },

    {
      id: 31,
      category: 'Sides',
      name: 'Livers and Roll',
      image: require('../assets/kk menu/livers nad roll.png'),
      price: 35.90,
    },

    {
      id: 32,
      category: 'Sides',
      name: 'Mocktails',
      image: require('../assets/kk menu/mocktails.png'),
      price: 24.90,
    },
    // New menu items
    // {
    //   id: 5,
    //   category: 'Sides',
    //   name: 'French Fries',
    //   image: require('../assets/kk menu/404.png'),
    //   price: 19.90,
    // },
    // {
    //   id: 6,
    //   category: 'Sides',
    //   name: 'Onion Rings',
    //   image: require('../assets/kk menu/404.png'),
    //   price: 24.90,
    // },
    // {
    //   id: 7,
    //   category: 'Drinks',
    //   name: 'Coke',
    //   image: require('../assets/kk menu/404.png'),
    //   price: 15.90,
    // },
    // {
    //   id: 8,
    //   category: 'Drinks',
    //   name: 'Sprite',
    //   image: require('../assets/kk menu/404.png'),
    //   price: 15.90,
    // },


  ];



  const filteredMenuItems = menuItems.filter(
    (item) => activeCategory === 'All' || item.category === activeCategory
  );

  useEffect(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      setSliderImages([sliderDesktop1, sliderDesktop2, sliderDesktop3]);
    } else {
      setSliderImages([sliderDesktop1, sliderDesktop2, sliderDesktop3]);
    }
  }, []);

  return (
    <PageContainer>
       <Slider images={sliderImages} />
      <Section>
        <SectionHeadline>Our Menu</SectionHeadline>
        <CategoriesContainer>
          {categories.map((category) => (
            <CategoryButton
              key={category}
              active={activeCategory === category}
              onClick={() => setActiveCategory(category)}
            >
              {category}
            </CategoryButton>
          ))}
        </CategoriesContainer>
        <MenuItemsContainer>
          {filteredMenuItems.map((item) => (
            <MenuItemCard key={item.id}>
              <MenuItemImage src={item.image} alt={item.name} />
              <NameCard>
                <MenuItemName>{item.name}</MenuItemName>
              </NameCard>
              <PriceCard>
                {item.variants ? (
                  item.variants.map((variant, index) => (
                    <MenuItemPrice key={index}>
                      {variant.variant} {variant.price.toFixed(2)}
                    </MenuItemPrice>
                  ))
                ) : (
                  <MenuItemPrice>{item.price.toFixed(2)}</MenuItemPrice>
                )}
              </PriceCard>
            </MenuItemCard>
          ))}
        </MenuItemsContainer>
      </Section>
    </PageContainer>
  );
}

export default Menu;
