import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from '../assets/logo.png'; // Replace with your logo if necessary

const HeaderContainer = styled.header`
  position: sticky;
  top: 0;
  z-index: 999;
  height: 100px;
  background-color: #fcee2b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  @media (max-width: 768px) {
    height: 80px;
  }
`;

const BlueSection = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100px;
  background-color: #1995d3;
  clip-path: polygon(75% 0, 100% 0, 100% 100%, 25% 100%);

  @media (max-width: 768px) {
    height: 80px;
    clip-path: polygon(85% 0, 100% 0, 100% 100%, 15% 100%);
  }
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 20px;
  align-items: center;
  z-index: 1001;

  a {
    text-decoration: none;
    color: #000;
    font-size: 18px;
    font-weight: 500;

    &:hover {
      color: #f7ed36;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Logo = styled.img`
  width: 90px;
  height: auto;
  z-index: 1001;

  @media (max-width: 768px) {
    width: 70px;
  }
`;

const HamburgerMenu = styled.div`
  display: none;
  cursor: pointer;
  z-index: 1001;
  position: absolute;
  right: 20px;
  top: 25px;

  background-color: #d31f2f; /* Red background */
  padding: 10px;
  border-radius: 8px; /* Rounded corners */

  @media (max-width: 768px) {
    display: block;
  }
`;

const MobileNav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  background-color: #fcee2b; /* Yellow background */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-in-out;
  z-index: 3000;
  padding-top: 50px;

  @media (min-width: 769px) {
    display: none;
  }
`;

const MobileNavLink = styled(NavLink)`
  color: #000;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  padding: 15px;
  display: block;
  width: 100%;

  &:hover {
    background-color: #1995d3; /* Blue on hover */
    color: #fff; /* White text on hover */
  }
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    document.body.style.overflow = isOpen ? 'auto' : 'hidden';
  };

  return (
    <>
      <HeaderContainer>
        <BlueSection />
        <Logo src={logo} alt="Krispy King Logo" />
        
        {/* Desktop Nav Links */}
        <NavLinks>
          <NavLink exact to="/">Home</NavLink>
          <NavLink to="/menu">Menu</NavLink>
          <NavLink to="/about">About Us</NavLink>
          <NavLink to="/franchising">Franchising</NavLink>
          {/* <NavLink to="/contact">Contact</NavLink> */}
        </NavLinks>

        {/* Hamburger Menu for Mobile */}
        <HamburgerMenu onClick={toggleMenu}>
          {isOpen ? <FaTimes size={24} color="#FFFFFF" /> : <FaBars size={24} color="#FFFFFF" />}
        </HamburgerMenu>
        
        {/* Mobile Nav */}
        <MobileNav open={isOpen}>
          <MobileNavLink exact to="/" onClick={toggleMenu}>Home</MobileNavLink>
          <MobileNavLink to="/menu" onClick={toggleMenu}>Menu</MobileNavLink>
          <MobileNavLink to="/about" onClick={toggleMenu}>About Us</MobileNavLink>
          <MobileNavLink to="/franchising" onClick={toggleMenu}>Franchising</MobileNavLink>
          {/* <MobileNavLink to="/contact" onClick={toggleMenu}>Contact</MobileNavLink> */}
        </MobileNav>
      </HeaderContainer>
    </>
  );
};

export default Header;
