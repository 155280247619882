import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Slider from '../components/Slider';
import burger from '../assets/burger.svg';
import sliderDesktop from '../assets/franshising.png';
import sliderMobile from '../assets/franshising.png';

// Styled components
const FranchiseSection = styled.section`
  background-color: #f7f7f7; /* Soft gray for modern touch */
  color: #333;
  padding: 0;
  text-align: left;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;
  color: #D61F30;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
`;

const Subtitle = styled.p`
  font-size: 22px;
  margin-bottom: 40px;
  color: #555;
  max-width: 800px;
  text-align: left;
  margin: 0;
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1200px;
  margin: 50px auto;
  text-align: left;
  padding: 0 20px;
`;

const FranchiseText = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
  color: #444;
  line-height: 1.8;
  max-width: 900px;
  text-align: left;
`;

const CTAButton = styled.a`
  background-color: #D61F30;
  color: white;
  padding: 15px 40px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 30px;
  text-decoration: none;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 30px;
  text-align: left;

  &:hover {
    background-color: #B81A28;
    transform: translateY(-2px);
  }
`;

const FAQSection = styled.div`
  background-color: #1995D3; /* Contrast background for FAQ section */
  padding: 60px 20px;
  color: white;
  text-align: left;
`;

const FAQHeader = styled.h2`
  font-size: 36px;
  color: white;
  margin-bottom: 40px;
  text-align: left;
`;

const FAQItem = styled.div`
  margin-bottom: 25px;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 15px;
  text-align: left;

  &:hover {
    opacity: 0.85;
  }
`;

const Question = styled.div`
  font-size: 22px;
  font-weight: bold;
  color: white;
  text-align: left;
`;

const Answer = styled.p`
  font-size: 16px;
  color: white;
  margin-top: 10px;
  text-align: left;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  transition: all 0.3s ease;
`;

const CTAForm = styled.form`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 600px;
  margin: 0 auto;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: left;
`;

const Input = styled.input`
  padding: 10px;
  padding-right: 15px; /* Add padding to the right */
  border: 1px solid #D61F30;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 10px; /* Add margin to the right */
  width: calc(100% - 25px); /* Adjust width if necessary to prevent overflow */
  text-align: left;
`;

const SubmitButton = styled.button`
  background-color: #D61F30;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #B81A28;
  }
`;

const FranchisePage = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [sliderImage, setSliderImage] = useState(sliderDesktop);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 768) {
        setSliderImage(sliderMobile);
      } else {
        setSliderImage(sliderDesktop);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <FranchiseSection>
      {/* Slider Section */}
      <Slider images={[sliderImage]} />

      {/* Franchise Content */}
      <ContentSection>
        <Title>WE HAVE THE SECRET RECIPE FOR SUCCESS!
        </Title>

        <FranchiseText>
          At Krispy King, we’re not just grilling and frying chicken—we’re building a kingdom. With the krispiest chicken in the land, grilled or fried, we’re spreading across Africa, creating jobs, serving up flavorful meals, and driving profits for our franchisees. In under two years, we’ve opened over 30 stores, and this is just the beginning. Ready to reign in the Krispy Kingdom? Join us, and together we’ll conquer Africa one krispy, grilled or fried bite at a time. Contact us using the email below, and we’ll get in touch with all the juicy details!
        </FranchiseText>

        <FranchiseText>
          franchising@krispykingsa.co.za
        </FranchiseText>
        
       

       
      
      </ContentSection>

      {/* CTA Form */}
      {/* <CTAForm>
        <center>
        <h2>Apply Now</h2>
        </center>
        
        <Input type="email" placeholder="Enter your email address" required />
        <Input type="text" placeholder="Enter your city" required />
        <Input type="text" placeholder="Enter your contact number" required />
        <SubmitButton type="submit">Submit</SubmitButton>
      </CTAForm> */}

      <br />

      {/* FAQs Section */}
      <FAQSection>
        <FAQHeader>Frequently Asked Questions</FAQHeader>

        <FAQItem onClick={() => toggleFAQ(0)}>
          <Question>How much does a Krispy King store cost?</Question>
          <Answer isOpen={openIndex === 0}>
            A Kirspy King franshise will cost you between R2 100 00 - R2 600 000 and the pricing depends on the size of the store.
          </Answer>
        </FAQItem>

        <FAQItem onClick={() => toggleFAQ(1)}>
          <Question>What are the different store options?</Question>
          <Answer isOpen={openIndex === 1}>
            You have 3 kinds of Krispy King stores 1 - Popular Garage model, 2 resturant sit down model 3 resturant and drive thru model. 
          </Answer>
        </FAQItem>

        <FAQItem onClick={() => toggleFAQ(2)}>
          <Question>What are the royalty fees?</Question>
          <Answer isOpen={openIndex === 2}>
            2% royalties and 1% Marketing.
          </Answer>
        </FAQItem>

        <FAQItem onClick={() => toggleFAQ(3)}>
          <Question>How much is the joining fee?</Question>
          <Answer isOpen={openIndex === 3}>
            The joining fee will cost you R150K
          </Answer>
        </FAQItem>

        <FAQItem onClick={() => toggleFAQ(4)}>
          <Question>How long does my store take to complete? </Question>
          <Answer isOpen={openIndex === 4}>
            Between 45 - 90 days
          </Answer>
        </FAQItem>

        
      </FAQSection>
    </FranchiseSection>
  );
};

export default FranchisePage;
