import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Slider from '../components/Slider';
import sliderDesktop from '../assets/about.jpeg';
import sliderMobile from '../assets/about.jpeg';

const PageContainer = styled.div`
  font-family: Arial, sans-serif;
  color: #fff;
`;

const Section = styled.section`
  background-color: #fff;
  padding: 50px 20px;
  text-align: center;
`;

const SectionHeadline = styled.h2`
  font-size: 32px;
  color: #D61F30; /* Main brand color */
  margin-bottom: 20px;
`;

const SectionText = styled.p`
  font-size: 18px;
  margin-bottom: 30px;
  color: #333;
`;

const CategoriesContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const CategoryButton = styled.button`
  background-color: ${({ active }) => (active ? '#D61F30' : 'white')}; /* Main brand color when active */
  color: ${({ active }) => (active ? 'white' : '#333')}; /* Text color */
  border: none;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;

  &:hover {
    background-color: ${({ active }) => (active ? '#D61F30' : '#D61F30')};
    color: ${({ active }) => (active ? 'white' : 'white')};
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 14px;
  }
`;

const AboutItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
const FranchiseSection = styled.section`
  background-color: #f7f7f7; /* Soft gray for modern touch */
  color: #333;
  padding: 0;
  text-align: left;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;
  color: #D61F30;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
`;

const Subtitle = styled.p`
  font-size: 22px;
  margin-bottom: 40px;
  color: #555;
  max-width: 800px;
  text-align: left;
  margin: 0;
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1200px;
  margin: 50px auto;
  text-align: left;
  padding: 0 20px;
`;

const FranchiseText = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
  color: #444;
  line-height: 1.8;
  max-width: 900px;
  text-align: left;
`;

const AboutItemCard = styled.div`
  background-color: #1995D3;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  max-width: 250px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 100%;
    width: 90%;
  }
`;

const AboutItemImage = styled.img`
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const AboutItemName = styled.h3`
  font-size: 18px;
  margin: 10px 0;
  color: #D61F30; /* Main brand color */
`;

const AboutItemPrice = styled.p`
  font-size: 16px;
  color: #333;
`;

function About() {
  const [sliderImage, setSliderImage] = useState(sliderDesktop);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 768) {
        setSliderImage(sliderMobile);
      } else {
        setSliderImage(sliderDesktop);
      }
    };

    // Set the initial image
    handleResize();

    // Add event listener to resize
    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  



  return (
    <PageContainer>
      {/* Pass the responsive slider image to the Slider component */}
      <Slider images={[sliderImage]} />
      {/* Franchise Content */}
      <ContentSection>
        <Title>About Us</Title>
        {/* <Subtitle>And the krispiest chicken in the land!</Subtitle> */}

        <FranchiseText>
          Since 2022, Krispy King has been serving up the krispiest, tastiest grilled and fried chicken—offering mouth-watering meals at prices that leave both your taste buds and wallet smiling. With over 30 franchised outlets across Africa and counting, we’ve quickly become one of the continent’s fastest-growing food families. But we’re not just building a chain of stores; we’re creating a community, one delicious meal at a time.        
        </FranchiseText>

        <FranchiseText>
          At Krispy King, our mission is simple: deliver irresistible chicken—whether it’s perfectly grilled or golden-fried—that’s both affordable and unforgettable. We pride ourselves on quality, from the first krispy bite of our fried chicken to the flavorful char of our grilled options.
        </FranchiseText>

        <FranchiseText>
          Our goal is to bring people together, from Cape Town to Cairo, with flavors that cross borders. With the African spirit of community at our core, we aim to serve more than just food—we create moments to savor. As we continue to grow, we’re spreading our wings even further, bringing the Krispy King experience to new locations across Africa and beyond.
        </FranchiseText>

        <FranchiseText>
          So, why let borders stop great chicken? At Krispy King, lekker, affordable, and always krispy—grilled or fried—is how we do it, one bite at a time.        
        </FranchiseText>
       

       
      
      </ContentSection>
      
    </PageContainer>
  );
}

export default About;
