import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

// Footer container styling
const FooterContainer = styled.footer`
  background-color: #D31F2F; /* Keep the background color */
  color: #fff;
  text-align: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;

  // Responsive styling for smaller screens
  @media (max-width: 768px) {
    padding: 15px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 10px;
    font-size: 12px;
  }
`;

// Social media section styling
const FollowUs = styled.h3`
  font-size: 20px;
  margin-bottom: 15px;
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 80px;  /* Increased gap between icons */
  margin-bottom: 20px;
`;

const SocialIconLink = styled.a`
  color: white;
  font-size: 30px;

  &:hover {
    color: #fff;
    opacity: 0.7;
  }
`;

// Footer component
const Footer = () => (
  <FooterContainer>
    {/* "Follow Us On" Heading */}
    <FollowUs>Follow us on:</FollowUs>

    {/* Social Media Icons */}
    <SocialIcons>
      <SocialIconLink
        href="https://www.tiktok.com/@krispy.king.rsa"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faTiktok} />
      </SocialIconLink>

      <SocialIconLink
        href="https://www.instagram.com/krispykingsa/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faInstagram} />
      </SocialIconLink>

      <SocialIconLink
        href="https://www.facebook.com/people/Krispy-King-SA/61554730087817/?mibextid=qi2Omg&rdid=7JSFHhODllnLJpmU&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FAaHufqwzL7HiZPiF%2F%3Fmibextid%3Dqi2Omg"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faFacebook} />
      </SocialIconLink>
    </SocialIcons>

    {/* Copyright Text */}
    <p>© 2024 Krispy King. All rights reserved.</p>
  </FooterContainer>
);

export default Footer;
