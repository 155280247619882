import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PromoVideo from '../components/PromoVideo';

import homeDesktopImage from '../assets/1.png';
import homeDesktopImage2 from '../assets/3.jpeg';
import homeMobileImage from '../assets/1.png';
import menuDesktopImage from '../assets/2.jpeg';

// Section wrapper for no-spacing layout and entire section adjustment
const NoSpacingSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  gap: 0;
  position: relative;

  /* Shift the entire section upward to overlap the video */
  margin-top: -450px; /* Default overlap for desktop */

  @media (max-width: 768px) {
    margin-top: -250px; /* Adjust overlap for mobile */
  }
`;

// Full-width overlay card with image
const FullWidthOverlayCard = styled.div`
  width: 100%;
  height: auto; 
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

// Button styled for order now, adjusted to be higher and bigger
const OrderNowButton = styled.a`
  position: absolute;
  bottom: 650px; /* Moves the button higher */
  right: 20px;
  background-color: #D31F2F;
  color: white;
  padding: 20px 40px; /* Larger padding for a bigger button */
  text-decoration: none;
  border-radius: 8px;
  font-size: 100px; /* Increased font size */
  font-weight: bold;
  text-align: center;

  &:hover {
    background-color: #B31C26;
  }

  @media (max-width: 768px) {
    padding: 15px 30px; /* Slightly smaller padding on mobile */
    font-size: 20px; /* Adjust font size for mobile */
    bottom: 20px; /* Adjust position for smaller screens */
  }
`;

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener to detect screen size changes
    window.addEventListener('resize', handleResize);

    // Check initial screen size
    handleResize();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <PromoVideo key={window.location.pathname} />

      {/* Section with no spacing between OverlayCards */}
      <NoSpacingSection>
        <FullWidthOverlayCard>
          <img 
            src={isMobile ? homeMobileImage : homeDesktopImage} 
            alt="Krispy King"
          />
        </FullWidthOverlayCard>
        
        <FullWidthOverlayCard>
          <img 
            src={isMobile ? menuDesktopImage : menuDesktopImage} 
            alt="Krispy King"
          />
        </FullWidthOverlayCard>

        <FullWidthOverlayCard>
          <img 
            src={isMobile ? homeDesktopImage2 : homeDesktopImage2} 
            alt="Krispy King"
          />
          {/* Order Now Button */}
          <OrderNowButton 
            href="https://www.ubereats.com/za/store/krispy-king/Y8PQU5dXWTmrJd_9noooNg?msockid=0cc8f20869426c692bb4e60868dc6dcc" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Order Now
          </OrderNowButton>
        </FullWidthOverlayCard>
      </NoSpacingSection>
    </div>
  );
};

export default Home;
